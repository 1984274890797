<template>
  <div class="preloader">
    <svg
      v-for="(_, idx) in 4"
      :key="idx"
      viewBox="0 0 311 280" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.21167" width="310" height="200" rx="16" fill="white"/>
      <rect x="0.000732422" y="238" width="310" height="18" rx="9" fill="white"/>
      <rect x="0.000732422" y="214" width="72" height="18" rx="9" fill="white"/>
      <rect x="0.000732422" y="262" width="310" height="18" rx="9" fill="white"/>
    </svg>
  </div>
</template>

<style scoped lang="scss">
  .preloader{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  svg{
    width: calc(50% - 8px);
    margin-bottom: 55px;
    animation-name: blink;
    animation-duration: .6s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
    @media (max-width: 1100px){
      width: 100%;
    }
  }
  @keyframes blink {
    0% { opacity: .5; }
    100% { opacity: 1; }
  }
</style>

